import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'

const t = buildTranslate('thanx_campaigns.performance')
const tError = buildTranslate('errors')

interface EmailEngagementOverviewProps {
  userIsAdmin: boolean
  merchant: Merchant
  emailEngagementOverviewPath: string
  emailEngagementOverviewHeight: number
}

const EmailEngagementOverview: React.FC<EmailEngagementOverviewProps> = ({
  userIsAdmin,
  merchant,
  emailEngagementOverviewPath,
  emailEngagementOverviewHeight,
}) => {
  const [css] = useStyletron()

  return (
    <div className="pb-l grey-05-bkg">
      <div className="container pl-0 pr-0">
        <Text.Header3 className="mt-l mb-xl" tag="div">
          {t('emailEngagement.title')}
        </Text.Header3>
        <Container
          className={`px-0 ${css({
            minHeight: `${emailEngagementOverviewHeight + 300}px`,
          })}`}
        >
          <Switch
            condition={userIsAdmin}
            fallback={
              <Text.BodyText3 className="mb-xl">
                {tError('permission_not_allowed')}
              </Text.BodyText3>
            }
          >
            <LookerIframe
              path={emailEngagementOverviewPath}
              merchant={merchant}
              height={`${emailEngagementOverviewHeight}px`}
            />
            <LookerFooter className="my-xl" />
          </Switch>
        </Container>
      </div>
    </div>
  )
}

export default EmailEngagementOverview

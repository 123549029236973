const errors = {
  thanx:
    'Some data on this page failed to load. Reload this page to try again.',
  coming_soon:
    'Some parts of this page won’t load correctly until you go live.',
  503: 'Connections to the Thanx service are taking too long. %{thanx} Code: %{code}',
  500: 'Something went wrong when we attempted to contact the Thanx service. %{thanx} Code: %{code}',
  forbidden_title: 'You don’t have permission to view this page',
  forbidden_message: 'Contact your admin to update your permission settings',
  invalid_token: 'The access token is invalid',
  expired_token: 'The access token expired',
  inaccessible: 'That page is currently inaccessible. Please try again.',
  permission_not_allowed: 'You don’t have permission to view this page',
}

export default errors
